#sign-widget {
    .widget-body {
        .info-card {
            cursor: auto !important;
            max-height: 230px !important;
            padding: 12px !important;
        }
    }

    #not-flex-widget {
        display: inline !important;

        .info-card {
            width: 96% !important;
        }
    }
}

#card-widget {
    text-align: left;

    .widget-title {
        display: flex;
        justify-content: space-between;
        font-family: ShellMedium, sans-serif;
        position: relative;
        user-select: none;

        .lfeExpertiseAreaSpan {
            color: #808285 !important;
            font-size: 15px;
            font-weight: normal;
            font-family: ShellBook, sans-serif;
        }


        .lfi-links {
            margin-left: 20px;

            span {
                font-size: 14px;
                color: #0099ba;
                margin-right: 20px;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        h1 {
            font-size: 20px;
            width: 40%;
        }

        #moderator-tools span {
            margin-right: 10px;
            color: #0099ba;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }

            svg {
                margin-top: -3px;
            }
        }

        .forum-filters {
            position: absolute;
            right: 10px;
            top: 26px;
            background-color: #fff;
            width: 250px;
            border: 1px solid #ccc;
            border-radius: 10px;
            padding: 14px 10px;
            box-shadow: 0px 2px 2px -2px rgba(127, 127, 127, 1);
            font-size: 14px;
        }

        #moderator-tools {
            #dropdown-menu-align-right {
                border: none;
                background-color: transparent;
                position: relative;
                left: 10px;
            }

            svg {
                font-size: 26px;
                color: #e1631c;
            }

            .dropdown-item {
                color: #0099ba !important;
            }

            a.dropdown-item:hover {
                color: #0099ba !important;
            }

            .dropdown-menu.show {
                transform: translate3d(-103px, 5px, 0px) !important;
            }

            .unhide {
                text-align: right;
                color: #0099ba;
                cursor: pointer;
                float: right;
                font-size: 17pt;
                font-family: 'ShellBook', sans-serif;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .hidden-webpart {
        background-color: #f5f5f5;
    }

    .widget-body {
        .discussion-info {
            margin-bottom: 5px;
            color: #808285 !important;
            cursor: default;
            display: block;
            /*margin-right: 50px;*/
            font-size: 13px;
            margin-top: 4px;
            font-family: Arial,Helvetica,sans-serif;
        }

        .sign-forum-title {
            font-size: 16px;
            color: #58595b;
            font-family: ShellMedium, sans-serif;
            cursor: pointer;

            &:hover {
                color: #0099ba;
                text-decoration: underline;
            }
        }

        .lfe-title {
            font-size: 16px;
            color: #58595b;
            font-family: ShellMedium, sans-serif;
            cursor: pointer;
        }

        .sign-discussion-title {
            font-size: 15px;
            color: #0099ba !important;
            font-family: 'ShellBook', sans-serif;
        }

        display: flex;

        .slick-slider, .slick-list, .slick-track, .slick-slide div {
            height: 100% !important;
        }

        .slick-dots {
            bottom: 5px;
        }

        .slick-dots li.slick-active button:before {
            opacity: .75;
            color: #fff;
            border: 1px solid #0099ba;
            border-radius: 11px;
            line-height: 16px;
            height: 16px;
            width: 16px;
            font-size: 14px;
            top: -1px;
        }

        .slick-dots li button:before {
            line-height: 16px;
            font-size: 12px;
        }

        .slick-prev {
            left: 15px !important;
            z-index: 1;
        }

        .slick-next {
            right: 15px !important;
        }

        .slick-next:before, .slick-prev:before {
            color: #0099ba;
        }

        .right-custom, .left-custom {
            position: absolute;
            right: 15px;
            z-index: 1;
            top: 45%;
            // background-color: #56afca;
            background-color: #ccc;
            color: #fff;
            cursor: pointer;
            transition: all 500ms;
        }

        .left-custom {
            left: 0px !important;
            right: inherit;
        }

        .right-custom:hover, .left-custom:hover {
            background-color: #656565;
            color: #fff;
        }

        .no-results-lfe {
            margin-top: 20% !important;
        }

        .no-discussion-info {
            margin-bottom: 8%;
        }

        .no-results {
            margin: 100px 0 0 0;
        }

        .info-card {
            padding: 20px;
            margin: 0px 15px 0 0;
            min-height: 212px;
            max-height: 212px;
            text-align: left;
            width: 32%;
            border: 1px solid #bebebe;
            transition: 500ms;
            cursor: pointer;

            &:hover {
                -webkit-box-shadow: 0px 0px 3px 0px rgba(127, 127, 127, 1);
                -moz-box-shadow: 0px 0px 3px 0px rgba(127, 127, 127, 1);
                box-shadow: 0px 0px 3px 0px rgba(127, 127, 127, 1);
                border-radius: 10px;
            }

            h1 {
                margin-bottom: 32px;
                font-size: 15px;
                color: #0099ba;
                font-family: ShellBook, sans-serif;
                line-height: 20px;
                cursor: pointer;
                min-height: 40px;

                &:hover {
                    color: #0099ba;
                    text-decoration: underline;
                }
            }

            span {
                color: #0099ba;
                // cursor: pointer;
                font-size: 14px;
                display: block;
                font-family: Arial, Helvetica, sans-serif;
                margin-bottom: 20px;
                // &:hover {
                //     text-decoration: underline;
                // }
            }

            span.info {
                color: #808285 !important;
                cursor: default;
                display: inline-block;
                margin-right: 50px;
            }

            .exp-apprvd {
                background-color: #cce6bb;
                // border: 1px solid #469046;
                border-radius: 20px;
                font-size: 14px;
                display: inline;
                margin-left: 10px;
                padding: 5px 20px;
                color: #3b5f3b !important;

                .info {
                    color: #58595b !important;
                }
            }

            .exp-apprvd-container {
                margin-bottom: 30px;
            }
        }

        .info-card-workday {
            padding: 20px;
            margin: 0px 15px 0 0;
            min-height: 212px;
            max-height: 212px;
            text-align: left;
            width: 32%;
            border: 1px solid #bebebe;
            transition: 500ms;
            cursor: pointer;

            &:hover {
                -webkit-box-shadow: 0px 0px 3px 0px rgba(127, 127, 127, 1);
                -moz-box-shadow: 0px 0px 3px 0px rgba(127, 127, 127, 1);
                box-shadow: 0px 0px 3px 0px rgba(127, 127, 127, 1);
                border-radius: 10px;
            }

            h1 {
                margin-bottom: 25px;
                font-size: 15px;
                color: #0099ba;
                font-family: ShellBook, sans-serif;
                line-height: 20px;
                cursor: pointer;
                min-height: 25px;

                &:hover {
                    color: #0099ba;
                    text-decoration: underline;
                }
            }

            span {
                color: #58595B;
                font-size: 14px;
                display: block;
                font-family: Arial, Helvetica, sans-serif;
                margin-bottom: 10px;
            }

            span.info {
                color: #808285 !important;
                cursor: default;
                display: inline-block;
                margin-right: 50px;
            }
        }
    }

    .signviewmore {
        .signviewmore-links-dropdown {
            margin-top: 15px;
            background: transparent !important;
            border: none !important;
            color: #0099ba !important;
            cursor: pointer;
            font-size: 14px;
            font-family: Arial, Helvetica, sans-serif;
        }

        .signviewmore-links-dropdown:hover {
            border: none;
            cursor: pointer;
        }

        .signviewmore-item {
            display: inline-flex;
            max-width: 425px;
        }

        .signviewmore-links-dropdown.dropdown-toggle:after {
            vertical-align: 0.155em !important;
            border-top: 0.6em solid !important;
        }
    }

    .view-more {
        margin-top: 15px;
        color: #0099ba;
        cursor: pointer;
        font-size: 14px;
        font-family: Arial, Helvetica, sans-serif;

        &:hover {
            text-decoration: underline;
        }
    }

    .card-loading {
        display: flex;

        .css-jn8o09 {
            margin: 0px 5px 0px 5px;
        }
    }
}

#dashboard.sign-min #sign-widget, #dashboard.lfe-min #lfe-widget, #dashboard.lfi-min #lfi-widget, #dashboard.ee-min #ee-widget, #dashboard.workday-min #workday-widget {
    transition: all 5s;

    .widget-title {
        h1 {
            width: 70%;
        }
    }

    .widget-body {
        flex-direction: column;

        .info-card {
            width: 100%;
            min-height: 20px;
            margin-bottom: 10px;

            h1 {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 10px;
            }

            .exp-apprvd-container {
                margin-bottom: 10px;
            }

            .info {
                margin-bottom: 0px;
            }
        }

        .info-card-workday {
            width: 100%;
            min-height: 20px;
            margin-bottom: 10px;

            h1 {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 10px;
            }

            .exp-apprvd-container {
                margin-bottom: 10px;
            }

            .info {
                margin-bottom: 0px;
            }
        }
    }

    .card-loading {
        flex-direction: column;

        div {
            height: 130px;
            overflow: hidden;
        }
    }

    .lfi-links {
        position: absolute;
        bottom: -446px;
        right: -10px;
        text-align: right;
    }
}

.edit-q {
    #dashboard.sign-min #sign-widget, #dashboard.lfe-min #lfe-widget, #dashboard.lfi-min #lfi-widget, #dashboard.ee-min #ee-widget {

        .widget-title {
            h1 {
                font-size: 16px;
                width: 55%;
            }
        }

        .sign-forum-filter {
            display: none;
        }
    }
}

#card-widget-modal {

    .modal-desc {
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .form-check {
        margin-bottom: 5px;
        display: flex;
        padding-left: 1.25rem;
        width: 33%;
    }

    .form-check-label {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 18px;
        position: relative;
        top: 2px;
        margin-left: 10px;
    }

    .sign-checkbox-wrapper, .common-checkbox-wrapper {
        margin-left: 15px;
        padding-right: 30px;
    }

    .common-checkbox-wrapper {
        margin-top: 10px;
    }

    .keyword-checkbox {
        margin-top: 50px;
        margin-bottom: -30px;
    }

    .keyword-helptext {
        float: inline-start;
        margin-left: 22%;
        color: #808285;
    }

    input[type=checkbox], input[type=radio] {
        width: 20px;
        height: 20px;
    }

    .query-form {
        display: flex;
        padding-left: 30px;
        margin-top: 10px;

        #function-select, #discpline-select, #PTE-select, #SME-select {
            flex-basis: 80%;
        }

        label {
            flex-basis: 20%;
        }

        textarea {
            width: 80%;
        }
    }

    .query-form-disabled {
        color: #ccc;
    }

    .modal-footer {
        margin-right: 30px;
        margin-top: 20px;

        .forum-selection {
            color: #e58271 !important;
            font-family: 'ShellBold', sans-serif;
            padding: 0px 20% 1% 0px;
        }
    }



    .sign-forum-wrapper {
        display: flex;
        flex-wrap: wrap;

        .sign-tech-func {
            width: 100%;
            margin: 10px 0;
            font-size: 16px;
            font-weight: bold;
        }

        .form-check-label {
            font-size: 16px;
            top: 4px;
        }
    }
}

.card-wiget-modal-body {
    max-height: 60vh;
    overflow: auto;
    min-height: 50vh;
}
